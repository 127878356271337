<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop"
      >
        <!-- Filters' Card -->
        <b-card>
          <p class="side-title">
            运单状态
          </p>
          <b-button
            variant="relief-primary"
            style="width:120px"
            @click="$emit('handleAll')"
          >
            全部({{ filters.total }})
          </b-button>
          <b-button
            style="width:120px"
            variant="relief-danger"
            class="mt-1"
            @click="$emit('handlePay')"
          >
            等待支付
          </b-button>
          <hr class="hr mt-2">
          <p class="side-title mb-2">
            高级搜索
          </p>
          <b-form @submit.prevent="handleSubmit">
            <b-form-group
              label="客户ID"
              label-for="inputId"
            >
              <b-form-input
                id="inputId"
                v-model="form.clientId"
                placeholder="请输入客户ID"
              />
            </b-form-group>
            <b-form-group
              label="收件人"
              label-for="inputRecipient"
            >
              <b-form-input
                id="inputRecipient"
                v-model="form.recipient"
                placeholder="请输入收件人"
              />
            </b-form-group>
            <b-form-group
              label="预报单号"
              label-for="inputPre"
            >
              <b-form-input
                id="inputPre"
                v-model="form.agent_order_id"
                placeholder="请输入预报单号"
              />
            </b-form-group>
            <b-form-group
              label="运单状态"
              label-for="status"
            >
              <v-select
                id="status"
                v-model="form.status_text"
                placeholder="请选择状态"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="statusOption"
              />
            </b-form-group>
            <b-form-group
              label="国家"
              label-for="country"
            >
              <v-select
                id="country"
                v-model="form.country_text"
                placeholder="请选择国家"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="countryOption"
              />
            </b-form-group>
            <b-form-group
              label="创建日期"
              label-for="create"
            >
              <flat-pickr
                id="create"
                v-model="form.dateNtim"
                class="form-control"
                placeholder="请选择日期时间"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
            <div class="d-flex align-items-center mt-4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="reset"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BForm,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        clientId: '',
        recipient: '',
        agent_order_id: '',
        status: '',
        status_text: '',
        country_id: '',
        country_text: '',
        dateNtim: '',
        created_at_start: '',
        created_at_end: '',
      },
      statusOption: [{ title: '等待支付', value: 'pay_now' }, { title: '已邮寄', value: 'sended' }],
      countryOption: [],
    }
  },
  created() {
    this.initCountry()
  },
  methods: {
    async initCountry() {
      try {
        const { data } = await this.$api.countryList({ page: 1, perPage: 1000 })
        this.countryOption = data.map(item => ({
          title: item.country,
          ...item,
        }))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error)
      }
    },
    handleSubmit() {
      this.form.status = this.form.status_text ? this.form.status_text.value : ''
      this.form.country_id = this.form.country_text ? this.form.country_text.id : ''
      if (this.form.dateNtim.length) {
        const dateArr = this.form.dateNtim.split('to')
        /* eslint-disable */
      this.form.created_at_start = dateArr[0].substr(0,10)
      this.form.created_at_end = dateArr[1].substr(1,11)
      }
      this.$emit('handleSubmitFilter',this.form)
    },
    reset(){
      for(const k in this.form) {
        this.form[k] = ''
      }
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.side-title{
  font-size:20px;
}
.hr{
  border:none;
  border-top:1px solid #ddd;
}
</style>
