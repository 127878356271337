<template>
  <b-modal
    id="trackModal"
    v-model="shipShow"
    ok-only
    :ok-title="$t('common.close')"
    modal-class="modal-primary"
    centered
    size="xl"
    title="已发货运单"
    @close="handleClose"
  >
    <b-row class="mb-3 mt-3">
      <b-col md="3">
        <b-form-group>
          <b-form-input
            v-model="sForm.batch"
            placeholder="批次名称"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <b-form-input
            v-model="sForm.shipment_id"
            placeholder="运单ID"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <b-form-input
            v-model="sForm.pre"
            placeholder="预报单"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <b-form-input
            v-model="sForm.client_id"
            placeholder="客户ID"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <b-form-input
            v-model="sForm.line"
            placeholder="线路"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <b-form-input
            v-model="sForm.country"
            placeholder="国家"
            type="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
        >
          搜索
        </b-button>
      </b-col>
    </b-row>
    <b-table
      ref="selectableTable"
      :items="items"
      :fields="shippedFields"
      responsive
    >
      <template #cell(parcel_weight)="data">
        <span>{{ data.item.parcel_weight }}kg</span>
      </template>
      <template #cell(trackNo)="data">
        <span id="trackNo">{{ data.item.trackNo }}</span>
      </template>
      <!-- eslint-disable-next-line -->
        <template #cell(action)="data">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
        >
          查看
        </b-button>
        <span>丨</span>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
        >
          打印运单
        </b-button>
        <span>丨</span>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
        >
          追踪
        </b-button>
      </template>
    </b-table>
    <template #modal-footer>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        size="md"
        class="float-right"
        @click="handleClose"
      >
        {{ $t('common.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BTable, BButton, BModal, BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable, BButton, BModal, BRow, BCol, BFormInput, BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shipShow: false,
      sForm: {
        batch: '',
        shipment_id: '',
        pre: '',
        client_id: '',
        line: '',
        country: '',
      },
      items: [{
        batch: '0811',
        shipment_id: 'AU1SPM0020030',
      }],
      shippedFields: [{
        key: 'batch',
        label: '发货批次',
      }, {
        key: 'shipment_id',
        label: '运单ID',
      }, {
        key: 'pre_entry',
        label: '预报单',
      }, {
        key: 'client',
        label: '客户',
      }, {
        key: 'receiver',
        label: '收件人',
      }, {
        key: 'destination',
        label: '目的地',
      }, {
        key: 'line',
        label: '线路选择',
      }, {
        key: 'freight',
        label: '运费',
      }, {
        key: 'qty',
        label: '数量',
      }, {
        key: 'weight',
        label: '重量',
      }, {
        key: 'status',
        label: '运单状态',
      }, {
        key: 'create_date',
        label: '创建日期',
      }, {
        key: 'action',
        label: '操作',
      }],
    }
  },
  watch: {
    show: {
      handler(val) {
        this.shipShow = val
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.shipShow = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
